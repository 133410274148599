










import { Component, Vue } from 'vue-property-decorator'
import Animation from '@/components/Animation/index.vue'
@Component({
  components: { Animation },
})
export default class NotFound extends Vue {}
